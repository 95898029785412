var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "pos": "fixed",
      "bottom": [_vm.isAuthenticated ? _vm.isHavingPendingNutritionist || _vm.isHavingPendingQuisionary ? '174px' : '74px' : '20px', '8px'],
      "right": "8px",
      "z-index": "10"
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.isOpenFloatingChat ? _c('c-box', {
    attrs: {
      "pos": "absolute",
      "background-color": "#008C81",
      "border-radius": "12px",
      "p": "10px",
      "z-index": "11",
      "box-shadow": "0px 12px 30px rgba(0, 0, 0, 0.3)",
      "bottom": "30px",
      "right": "30px",
      "min-width": "290px"
    }
  }, [_c('c-flex', {
    attrs: {
      "gap": "8px",
      "justify-content": "space-between"
    }
  }, [_c('c-box', [_c('c-text', {
    attrs: {
      "color": "#FFFFFF",
      "font-family": "Roboto",
      "font-size": "14px",
      "mb": "4px"
    }
  }, [_vm._v(" Masih bingung dengan program kami? ")]), _c('c-text', {
    attrs: {
      "color": "#FFFFFF",
      "font-family": "Roboto",
      "font-size": "14px",
      "mb": "6px"
    }
  }, [_vm._v(" Yuk, ngobrol dengan minela! ")])], 1), _c('c-flex', {
    attrs: {
      "_hover": {
        backgroundColor: 'primary.500'
      },
      "height": "22px",
      "width": "22px",
      "border-radius": "50%",
      "justify-content": "center",
      "align-items": "center",
      "cursor": "pointer"
    },
    on: {
      "click": _vm.closeFloatingChat
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icon-close-black.svg'),
      "height": "20",
      "width": "20",
      "stroke": "#FFFFFF"
    }
  })], 1)], 1), _c('c-input-group', [_c('c-input-left-element', {
    attrs: {
      "color": "gray.300",
      "font-size": "1.2em"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-chat-gray.svg'),
      "width": "20px",
      "height": "20px"
    }
  })], 1), _c('c-input', {
    attrs: {
      "font-family": "Roboto",
      "placeholder": "Tulis Pertanyaanmu...",
      "font-size": "14px",
      "pr": "2.5rem"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.sendWhatsappMessage();
      }
    },
    model: {
      value: _vm.whatsappMessage,
      callback: function callback($$v) {
        _vm.whatsappMessage = $$v;
      },
      expression: "whatsappMessage"
    }
  }), _c('c-input-right-element', [_c('c-box', {
    attrs: {
      "as": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.sendWhatsappMessage();
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-send-green.svg'),
      "width": "20px",
      "height": "20px"
    }
  })], 1)], 1)], 1)], 1) : _vm._e()], 1), _c('c-box', {
    attrs: {
      "pos": "relative",
      "d": "flex",
      "w": "100%",
      "justify-content": "flex-end",
      "z-index": "12"
    }
  }, [_c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        transitionDuration: '300ms',
        ':hover': {
          transform: 'scale(1.1)'
        },
        ':active': {
          transform: 'scale(0.9)'
        }
      },
      expression: "{\n        transitionDuration: '300ms',\n        ':hover': {\n          transform: 'scale(1.1)',\n        },\n        ':active': {\n          transform: 'scale(0.9)',\n        },\n      }"
    }],
    attrs: {
      "as": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.toggleFloatingChat();
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/logos_whatsapp-icon.svg'),
      "width": "45px",
      "height": "45px",
      "ml": "auto"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }