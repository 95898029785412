var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "position": "fixed",
      "flex-dir": "column",
      "h": "100vh",
      "w": "100vw",
      "top": "0",
      "left": "0",
      "bg": "white",
      "z-index": "7",
      "padding": "60px 0 60px 0"
    }
  }, [_c('c-flex', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        gap: '20px'
      },
      expression: "{ gap: '20px' }"
    }],
    staticClass: "test",
    attrs: {
      "mt": "20px",
      "flex": "1",
      "flex-wrap": "wrap",
      "align-items": "center",
      "align-content": "flex-start",
      "justify-content": "center",
      "bg": "white",
      "overflow": "auto"
    }
  }, _vm._l(_vm.menus, function (menu) {
    return _c('c-link', {
      key: menu.name,
      attrs: {
        "as": "router-link",
        "to": menu.to,
        "d": "flex",
        "flex-dir": "column",
        "align-items": "center",
        "justify-content": "center",
        "min-height": "165px",
        "w": "100%",
        "max-width": "50%",
        "border": "1px solid #008C81",
        "border-radius": "12px",
        "text-align": "center",
        "color": "primary.400",
        "py": "34px",
        "px": "10px",
        "flex-basis": "42%"
      }
    }, [_c('c-image', {
      attrs: {
        "flex": "2",
        "alt": menu.name,
        "src": menu.icon,
        "w": "56px",
        "h": "56px"
      }
    }), _c('c-flex', {
      attrs: {
        "flex": "1",
        "align-items": "center",
        "justify-content": "center"
      }
    }, [_c('c-text', {
      attrs: {
        "font-weight": "500",
        "font-size": "14px",
        "color": "#111111"
      }
    }, [_vm._v(" " + _vm._s(menu.name) + " ")])], 1)], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }