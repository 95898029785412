var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "id": "bottomNavigation",
      "as": "nav",
      "pos": "fixed",
      "left": "0",
      "bottom": "0px",
      "h": "64px",
      "w": "100vw",
      "bg": "white",
      "border-top": "0.5px solid #C4C4C4",
      "align-items": "center",
      "justify-content": "space-around",
      "box-shadow": "4px -4px 50px 5px rgba(0, 0, 0, 0.15)",
      "display": ['flex', 'none'],
      "py": "10px",
      "px": "4px",
      "z-index": "11"
    }
  }, [_vm.isMenuActive ? _c('portal', {
    attrs: {
      "to": "main"
    }
  }, [_c('modal-menu', {
    attrs: {
      "z-index": "1000"
    }
  })], 1) : _vm._e(), _vm._l(_vm.menus, function (menu) {
    return [menu.route == null ? _c('c-flex', {
      key: menu.name
    }, [_c('c-button', {
      attrs: {
        "variant": "ghost",
        "p": "0",
        "m": "0",
        "flex-direction": "column",
        "justify-content": "center",
        "align-items": "center"
      },
      on: {
        "click": function click($event) {
          return _vm.onMenuClicked(menu);
        }
      }
    }, [_c('c-image', {
      staticClass: "icon",
      attrs: {
        "alt": menu.name,
        "src": menu.icon,
        "w": "24px",
        "h": "24px"
      }
    }), _c('c-text', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          '&.active': {
            color: 'primary.400'
          }
        },
        expression: "{\n            '&.active': {\n              color: 'primary.400',\n            },\n          }"
      }],
      class: {
        active: menu.isActive
      },
      attrs: {
        "font-size": "12px",
        "font-weight": "400",
        "font-family": "Roboto",
        "color": "#555555"
      }
    }, [_vm._v(" " + _vm._s(menu.name) + " ")])], 1)], 1) : _vm._e(), menu.route != null ? _c('c-link', {
      key: menu.name,
      staticClass: "menu-item",
      attrs: {
        "as": "router-link",
        "to": menu.route,
        "exact-active-class": "active"
      },
      on: {
        "click": function click($event) {
          return _vm.onMenuClicked(menu);
        }
      }
    }, [_c('c-flex', {
      attrs: {
        "flex-direction": "column",
        "justify-content": "center",
        "align-items": "center"
      }
    }, [_c('c-image', {
      staticClass: "icon",
      attrs: {
        "alt": menu.name,
        "src": menu.icon,
        "w": "24px",
        "h": "24px"
      }
    }), _c('c-text', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          '&.active': {
            color: 'primary.400'
          }
        },
        expression: "{\n            '&.active': {\n              color: 'primary.400',\n            },\n          }"
      }],
      class: {
        active: menu.isActive
      },
      attrs: {
        "font-size": "12px",
        "font-weight": "400",
        "color": "#555555",
        "font-family": "Roboto"
      }
    }, [_vm._v(" " + _vm._s(menu.name) + " ")])], 1)], 1) : _vm._e()];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }