<template>
  <c-flex
    position="fixed"
    flex-dir="column"
    h="100vh"
    w="100vw"
    top="0"
    left="0"
    bg="white"
    z-index="7"
    padding="60px 0 60px 0"
  >
    <c-flex
      v-chakra="{ gap: '20px' }"
      mt="20px"
      flex="1"
      flex-wrap="wrap"
      align-items="center"
      align-content="flex-start"
      justify-content="center"
      bg="white"
      overflow="auto"
      class="test"
    >
      <c-link
        v-for="menu in menus"
        :key="menu.name"
        as="router-link"
        :to="menu.to"
        d="flex"
        flex-dir="column"
        align-items="center"
        justify-content="center"
        min-height="165px"
        w="100%"
        max-width="50%"
        border="1px solid #008C81"
        border-radius="12px"
        text-align="center"
        color="primary.400"
        py="34px"
        px="10px"
        flex-basis="42%"
      >
        <c-image
          flex="2"
          :alt="menu.name"
          :src="menu.icon"
          w="56px"
          h="56px"
        />
        <c-flex
          flex="1"
          align-items="center"
          justify-content="center"
        >
          <c-text
            font-weight="500"
            font-size="14px"
            color="#111111"
          >
            {{ menu.name }}
          </c-text>
        </c-flex>
      </c-link>
    </c-flex>
  </c-flex>
</template>

<script>
export default {
  name: 'ModalMenu',
  components: {},
  data() {
    return {
      menus: [
        {
          name: 'Program',
          icon: require('@/assets/icon-menu-program.svg'),
          to: { name: 'client.profile.program' },
        },
        {
          name: 'Ahli Gizi',
          icon: require('@/assets/icon-menu-users.svg'),
          to: { name: 'client.nutritionists' },
        },
        {
          name: 'Meal Plan',
          icon: require('@/assets/icon-menu-meat.svg'),
          to: { name: 'meal-plan' },
        },
        {
          name: 'Diet Coaching dan Challenge',
          icon: require('@/assets/icon-menu-challenge.svg'),
          to: { name: 'content-challenge.coaching-course' },
        },
        {
          name: 'Progress & Tracker',
          icon: require('@/assets/icon-menu-progress.svg'),
          to: { name: 'client.progress-tracker' },
        },
        {
          name: 'Catatan Ahli Gizi',
          icon: require('@/assets/icon-menu-notes.svg'),
          to: { name: 'client.follow-up-notes' },
        },
        {
          name: 'Glossary',
          icon: require('@/assets/ic-menus-glossary.svg'),
          to: ('/glossaries'),
        },
      ],
    }
  },
}
</script>
