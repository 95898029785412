<template>
  <c-box 
    pos="fixed"
    :bottom="[isAuthenticated ? (isHavingPendingNutritionist || isHavingPendingQuisionary ? '174px' : '74px') : '20px', '8px']"
    right="8px"
    z-index="10"
  >
    <transition name="fade">
      <c-box
        v-if="isOpenFloatingChat"
        pos="absolute"
        background-color="#008C81"
        border-radius="12px"
        p="10px"
        z-index="11"
        box-shadow="0px 12px 30px rgba(0, 0, 0, 0.3)"
        bottom="30px"
        right="30px"
        min-width="290px"
      >
        <c-flex
          gap="8px"
          justify-content="space-between"
        >
          <c-box>
            <c-text
              color="#FFFFFF"
              font-family="Roboto"
              font-size="14px"
              mb="4px"
            >
              Masih bingung dengan program kami?
            </c-text>
            <c-text
              color="#FFFFFF"
              font-family="Roboto"
              font-size="14px"
              mb="6px"
            >
              Yuk, ngobrol dengan minela!
            </c-text>
          </c-box>
          <c-flex
            :_hover="{
              backgroundColor: 'primary.500',
            }"
            height="22px"
            width="22px"
            border-radius="50%"
            justify-content="center"
            align-items="center"
            cursor="pointer"
            @click="closeFloatingChat"
          >
            <inline-svg
              :src="require('@/assets/icon-close-black.svg')"
              height="20"
              width="20"
              stroke="#FFFFFF"
            />
          </c-flex>
        </c-flex>
        <c-input-group>
          <c-input-left-element
            color="gray.300"
            font-size="1.2em"
          >
            <c-image
              :src="require('@/assets/ic-chat-gray.svg')"
              width="20px"
              height="20px"
            />
          </c-input-left-element>
          <c-input
            v-model="whatsappMessage"
            font-family="Roboto"
            placeholder="Tulis Pertanyaanmu..."
            font-size="14px"
            pr="2.5rem"
            @keyup.enter="sendWhatsappMessage()"
          />
          <c-input-right-element>
            <c-box
              as="button"
              @click="sendWhatsappMessage()"
            >
              <c-image
                :src="require('@/assets/ic-send-green.svg')"
                width="20px"
                height="20px"
              />
            </c-box>
          </c-input-right-element>
        </c-input-group>
      </c-box>
    </transition>
    <c-box
      pos="relative"
      d="flex"
      w="100%"
      justify-content="flex-end"
      z-index="12"
    >
      <c-box
        v-chakra="{
          transitionDuration: '300ms',
          ':hover': {
            transform: 'scale(1.1)',
          },
          ':active': {
            transform: 'scale(0.9)',
          },
        }"
        as="button"
        @click="toggleFloatingChat()"
      >
        <c-image
          :src="require('@/assets/logos_whatsapp-icon.svg')"
          width="45px"
          height="45px"
          ml="auto"
        />
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FloatingChat',
  data() {
    return {
      isOpenFloatingChat: true,
      whatsappMessage: '',
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      isHavingPendingNutritionist: 'clients/isHavingPendingNutritionist',
      isHavingPendingQuisionary: 'clients/isHavingPendingQuisionary',
    }),
  },
  methods: {
    toggleFloatingChat() {
      this.isOpenFloatingChat = !this.isOpenFloatingChat
    },
    openFloatingChat() {
      this.isOpenFloatingChat = true
    },
    closeFloatingChat() {
      this.isOpenFloatingChat = false
    },
    sendWhatsappMessage() {
      window
        .open(
          `https://wa.me/6281213734726?text=${this.whatsappMessage}`,
          '_blank',
        )
        .focus()  
    },
  },
}
</script>

<style scoped>
.fade-enter-active {
  animation: fadeInUp 0.5s;
}

.fade-leave-active {
  animation: fadeInUp 0.5s reverse;
}

.fade-enter,
.fade-leave-to {
  /* transform: translateX(10px); */
  opacity: 0;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
</style>
